import * as React from 'react'
import { Link, graphql } from 'gatsby'

const PrimaryArticle = ({ data }) => {
    return (
      //https://api.clubmella.com/storage/images_disk/
        data.map((node, i) => (
          <div key={i}>
          <Link to={`/article/${node.slug}`}>
                <div className="flex flex-col md:flex-row md:space-x-12">
                    <div className="flex-1 flex items-center order-2 md:order-1">
                        <div className="p-0 md:p-6">
                            <h1 className="text-2xl">{node.title_en}</h1>
                            <p>{node.subtitle_en}</p>
                        </div>
                    </div>
                    <div className="flex-1 order-1 md:order-2 mb-6 md:mb-0">
                        <img src={`https://api.clubmella.com/storage/images_disk/${node.image != null ? node.image : "" }`} alt="placeholder" />
                    </div>
                </div>
            </Link>
            <Link to={`/article/author/${node.author_slug}`}>
              <h3>{node.author_en}</h3>
            </Link>
          </div>

        ))
    )
}

const SecondaryArticle = ({ data }) => {
  return (
      data.map((node, i) => (
        <div className="flex flex-row space-x-12 border-t border-b" key={i}>
        <div className="flex-1 flex items-center">
            <div className="p-6">
              <Link to={`/article/${node.slug}`}>
                    <p className="mb-3 text-skin-muted">
                      {node.category_en}
                    </p>
                    <h1 className="text-lg font-medium">
                      {node.title_en}
                    </h1>
                    <p>
                      {node.subtitle_en}
                    </p>
                    <p className="mt-3 text-skin-muted text-sm">
                        March 05, 2022
                    </p>
               </Link>
               <Link to={`/article/author/${node.author_slug}`}>
                <h3>{node.author_en}</h3>
               </Link>
            </div>
        </div>
    </div>
      ))
  )
}

const AllArticle = ({ data }) => {
  return (
      data.map((node, i) => (
      <div className='event-card bg-bown-light p-4 mb-8' key={i}>
        <Link to={`/article/${node.slug}`}>
          <div>
            <div className='mb-4'>

              <div class="container-image-blog">
                <div class="image-blog" style={{  
                  backgroundImage: "url(https://api.clubmella.com/storage/images_disk/" + node.image + ")",
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                  }}>
                  {/* <img src={`https://api.clubmella.com/storage/images_disk/${node.image != null ? node.image : "" }`}/> */}
                </div>
                <div class="corner-top"></div>
                <div class="corner-top-in-left"></div>
                <div class="corner-top-in-right"></div>
                
                <div class="corner-bottom"></div>
                <div class="corner-bottom-in-left"></div>
                <div class="corner-bottom-in-right"></div>
              </div>

              
            </div>
            <div className='text-3xl text-comorant'>
            {node.title_en}
            </div>
          </div>
        </Link>
      </div>
      ))
  )   
}
export {PrimaryArticle, SecondaryArticle, AllArticle}