import * as React from 'react'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { AllArticle } from '../../../components/articles/index-articles'
import { graphql } from 'gatsby'

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} className="underline">
          {children}
        </a>
      )
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2>{children}</h2>
    },
  },
}

const AuthorPost = ({ data}) => {
    console.log("data template : ", data);
    return (
        <>
        <h1>Author</h1>
            <div className="container mx-auto grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 md:flex-row mt-6 md:space-x-12">
              <AllArticle data={data.gogobackend.all_author}/>
            </div>
        </>
        
    )
}

export const queryPrimary = graphql`
query ($slug: String) {
  gogobackend {
      all_author: news_articles_content(
        status: "Published"
        author_slug: $slug
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
      }
    }
  }
`

export default AuthorPost

